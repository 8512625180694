import "./surveyTypes";

class SurveyEngine {
  constructor() {
    this.fields = [];
  }

  /**
   * Get the data type
   * @param {String} field_type Type of the field
   * @return {string}
   **/
  static getFieldDataType(field_type) {
    switch (field_type) {
      case "text":
        return "string";
      case "number":
        return "integer";
      case "range":
        return "integer";
      case "select":
        return "string";
      case "yesno":
        return "string";
      case "single_choice":
        return "string";
      case "condition":
        return "string";
      default:
        return "string";
    }
  }

  /**
   * Get the submission object from the fields
   * @param {FormFieldObject[]} fields
   * @return {FormFieldSubmissionObject}
   **/
  static getSubmissionFromFields(fields) {
    let submission = {};
    fields.forEach((field) => {
      submission[field.field_key] = {
        field_key: field.field_key,
        field_type: field.field_type,
        field_data_type: this.getFieldDataType(field.field_type),
        field_question: field.field_question,
        field_required: field.field_required,
        field_answer: null,
        field_multi_answer: [],
      };
      if (field.id) submission[field.field_key]["field_id"] = field.id;
    });
    return submission;
  }
}

class FormField {
  /**
   * @param {string} field_key
   * @param {string} field_type
   * @param {string} field_label
   * @param {string} field_question
   * @param {string} field_helper
   * @param {string} field_placeholder
   * @param {boolean} field_required
   * @param {string[]} field_items
   * @param {string} field_validation
   * @param {number} field_min_value
   * @param {number} field_max_value
   * @param {number} field_matrix_x
   * @param {number} field_matrix_y
   * @param {boolean} field_is_conditional
   * @param {string} field_dependency
   * @param {string} field_condition
   * @param {number} field_position
   * @param {boolean} field_is_active
   * */
  constructor(
    field_key,
    field_type,
    field_label,
    field_question,
    field_helper,
    field_placeholder,
    field_required,
    field_items,
    field_validation,
    field_min_value,
    field_max_value,
    field_matrix_x,
    field_matrix_y,
    field_is_conditional,
    field_dependency,
    field_condition,
    field_position,
    field_is_active
  ) {
    this.field_key = field_key;
    this.field_type = field_type;
    this.field_label = field_label;
    this.field_question = field_question;
    this.field_helper = field_helper;
    this.field_placeholder = field_placeholder;
    this.field_required = field_required;
    this.field_items = field_items;
    this.field_validation = field_validation;
    this.field_min_value = field_min_value;
    this.field_max_value = field_max_value;
    this.field_matrix_x = field_matrix_x;
    this.field_matrix_y = field_matrix_y;
    this.field_is_conditional = field_is_conditional;
    this.field_dependency = field_dependency;
    this.field_condition = field_condition;
    this.field_position = field_position;
    this.field_is_active = field_is_active;
  }
}

/**
 * Generate a random key for a field
 * @return {string} Random Field Key (Example: field-123hdh3)
 **/
const generateFieldKey = () => {
  let random =
    Math.random().toString(36).substring(2, 5) +
    Math.random().toString(36).substring(2, 5);
  return `field-${random}`;
};

/**
 * Generate a new field
 * @return {object}
 **/
const generateFormField = ({
  field_type,
  field_label,
  field_question,
  field_helper,
  field_placeholder,
  field_required,
  field_items,
  field_validation,
  field_min_value,
  field_max_value,
  field_matrix_x,
  field_matrix_y,
  field_is_conditional,
  field_dependency,
  field_condition,
  field_position,
  field_is_active,
}) => {
  let field_key = generateFieldKey();
  return new FormField(
    field_key,
    field_type,
    field_label,
    field_question,
    field_helper,
    field_placeholder,
    field_required,
    field_items,
    field_validation,
    field_min_value,
    field_max_value,
    field_matrix_x,
    field_matrix_y,
    field_is_conditional,
    field_dependency,
    field_condition,
    field_position,
    field_is_active
  );
};

export { SurveyEngine, generateFormField };
