import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import "./types";
import { cleanPayload, getParams } from "@/services/api/utils";

const SURVEY_URI = Settings.API_URI_SURVEY;

/**
 * Get a survey of a project
 * @param { string } project_id ID of the project
 * @return { Promise<SurveyObject> }
 * @throws { APIError }
 **/
const getSurvey = (project_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${SURVEY_URI}/`,
      params: getParams({ project_id: project_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new survey
 * @param { string } project_id ID of the project
 * @param { SurveyCreateObject } data Payload
 * @return { Promise<SurveyObject> }
 * @throws { APIError }
 **/
const createSurvey = (project_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${SURVEY_URI}/`,
      params: getParams({ project_id: project_id }),
      data: cleanPayload(data),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get all fields of a survey
 * @param {string} survey_id ID of the survey
 * @return {Promise<SurveyFieldObject>}
 * @throws {APIError}
 **/
const getSurveyFields = (survey_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${SURVEY_URI}/fields`,
      params: getParams({ survey_id: survey_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a survey field
 * @param {string} survey_id ID of the survey
 * @param {object} data Payload
 * @returns {Promise<SurveyFieldObject>}
 * @throws {APIError}
 **/
const createSurveyField = (survey_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${SURVEY_URI}/fields`,
      params: getParams({ survey_id: survey_id }),
      data: data,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update a survey field
 * @param {string} fieldId ID of the form field
 * @param {object} data
 **/
const updateSurveyField = (fieldId, data) => {
  let config = {
    method: "patch",
    url: `${SURVEY_URI}/fields`,
    params: getParams({ field_id: fieldId }),
    data: cleanPayload(data),
  };

  return Interceptor(config);
};

/**
 * Create a survey field
 * @param {string} survey_id ID of the survey
 * @param {string} field_id Payload
 * @returns {Promise<SurveyFieldObject[]>}
 * @throws {APIError}
 **/
const deleteSurveyField = (survey_id, field_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: `${SURVEY_URI}/fields`,
      params: getParams({ survey_id: survey_id, field_id: field_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getFieldAnswersCount = (field_id) => {
  return Interceptor({
    method: "GET",
    url: `${SURVEY_URI}/fields/answers/count`,
    params: getParams({ field_id: field_id }),
  });
};

export {
  getSurvey,
  createSurvey,
  getSurveyFields,
  createSurveyField,
  deleteSurveyField,
  updateSurveyField,
  getFieldAnswersCount,
};
