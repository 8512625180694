<template>
  <div>
    <!-- Empty state -->
    <iq-empty-state
      icon="question_mark"
      title="Bisher keine Fragen"
      v-if="fields.length === 0"
    />

    <!-- Survey -->
    <div v-else>
      <div class="survey">
        <!-- Field -->
        <div
          v-for="(field, index) in sortedFields"
          :key="field.field_key"
          :class="shouldShowInactiveFields(field.field_is_active) ? 'survey--field' : ''"
        >
          <div v-if="shouldShowInactiveFields(field.field_is_active) && !field.field_is_conditional">
            <!-- Question -->
            <div class="md-title">
              {{ index + 1 }}. {{ field.field_question }}
              <span v-if="field.field_required">*</span>
              <iq-status-pill :status="shouldShowInactiveFields(field.field_is_active) ? 'active' : 'inactive'" v-if="isEditable" style="font-size: 14px" />
              <md-tooltip v-if="field.field_required" md-direction="top"
                >Dieses Feld ist erforderlich</md-tooltip
              >
            </div>

            <!-- Field key -->
            <div class="md-body-2" v-if="isEditable">{{ field.field_key }}</div>

            <!-- Text input -->
            <md-field v-if="field.field_type === 'text'">
              <md-input
                :type="field.field_type"
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
              />
            </md-field>

            <!-- Number input -->
            <md-field v-if="field.field_type === 'number'">
              <md-input
                :type="field.field_type"
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
                :min="field.field_min_value"
                :max="field.field_max_value"
              />
              <span class="md-helper-text"
                >Zwischen {{ field.field_min_value | rangeSliderFilter }} und
                {{ field.field_max_value }} erlaubt</span
              >
            </md-field>

            <!-- Range slider -->
            <div class="iq-field" v-if="field.field_type === 'range'">
              <input
                type="range"
                :min="field.field_min_value"
                :max="field.field_max_value"
                v-model="submission[field.field_key].field_answer"
                class="iq-slider"
              />
              <span class="range-slider-text"
                >{{
                  submission[field.field_key].field_answer | rangeSliderFilter
                }}/{{ field.field_max_value }}</span
              >
            </div>

            <!-- Select -->
            <md-field v-if="field.field_type === 'select'">
              <md-select
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
              >
                <md-option
                  v-for="(option, index) in field.field_items"
                  :key="index"
                  :value="option"
                  >{{ option }}</md-option
                >
              </md-select>
            </md-field>

            <!-- Radio yes or no -->
            <div class="iq-yesno" v-if="field.field_type === 'yesno'">
              <md-radio
                v-model="submission[field.field_key].field_answer"
                value="Ja"
                class="md-primary"
                >Ja</md-radio
              >
              <md-radio
                v-model="submission[field.field_key].field_answer"
                value="Nein"
                >Nein</md-radio
              >
            </div>

            <!-- Single choice -->
            <div class="iq-yesno" v-if="field.field_type === 'single_choice'">
              <div v-for="(option, index) in field.field_items" :key="index">
                <md-radio
                  v-model="submission[field.field_key].field_answer"
                  :value="option"
                  >{{ option }}</md-radio
                >
              </div>
            </div>

            <!-- Multiple choice -->
            <div class="iq-yesno" v-if="field.field_type === 'multiple_choice'">
              <div v-for="(option, index) in field.field_items" :key="index">
                <md-checkbox
                  v-model="submission[field.field_key].field_multi_answer"
                  :value="option"
                  >{{ option }}</md-checkbox
                >
              </div>
            </div>

            <!-- Additional Info -->
            <div class="survey--field-description" v-if="field.field_helper">
              {{ field.field_helper }}
            </div>
          </div>

          <div
            v-if="shouldShowInactiveFields(field.field_is_active) && field.field_is_conditional"
            :class="
              isFieldActive(field.field_dependency, field.field_condition)
                ? ''
                : 'lower-opacity disabled-cursor'
            "
          >
            <!-- Question -->
            <div class="md-title">
              {{ index + 1 }}. {{ field.field_question }}
              <span v-if="field.field_required">*</span>
              <md-tooltip v-if="field.field_required" md-direction="top"
                >Dieses Feld ist erforderlich</md-tooltip
              >
            </div>

            <!-- Field key -->
            <div class="md-body-2" v-if="isEditable">{{ field.field_key }}</div>
            <div class="md-caption" v-if="isEditable">
              if "{{ field.field_dependency }}" = "{{ field.field_condition }}"
            </div>

            <!-- Text input -->
            <md-field v-if="field.field_type === 'text'">
              <md-input
                :type="field.field_type"
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
                :disabled="
                  !isFieldActive(field.field_dependency, field.field_condition)
                "
              />
            </md-field>

            <!-- Number input -->
            <md-field v-if="field.field_type === 'number'">
              <md-input
                :type="field.field_type"
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
                :min="field.field_min_value"
                :max="field.field_max_value"
                :disabled="
                  !isFieldActive(field.field_dependency, field.field_condition)
                "
              />
              <span class="md-helper-text"
                >Zwischen {{ field.field_min_value | rangeSliderFilter }} und
                {{ field.field_max_value }} erlaubt</span
              >
            </md-field>

            <!-- Range slider -->
            <div class="iq-field" v-if="field.field_type === 'range'">
              <input
                type="range"
                :min="field.field_min_value"
                :max="field.field_max_value"
                v-model="submission[field.field_key].field_answer"
                class="iq-slider"
              />
              <span class="range-slider-text"
                >{{
                  submission[field.field_key].field_answer | rangeSliderFilter
                }}/{{ field.field_max_value }}</span
              >
            </div>

            <!-- Select -->
            <md-field v-if="field.field_type === 'select'">
              <md-select
                v-model="submission[field.field_key].field_answer"
                :placeholder="field.field_placeholder"
                :disabled="
                  !isFieldActive(field.field_dependency, field.field_condition)
                "
              >
              <md-option value="">{{  }}</md-option>
                <md-option
                  v-for="(option, index) in field.field_items"
                  :key="index"
                  :value="option"
                  >{{ option }}</md-option
                >
              </md-select>
            </md-field>

            <!-- Radio yes or no -->
            <div class="iq-yesno" v-if="field.field_type === 'yesno'">
              <md-radio
                v-model="submission[field.field_key].field_answer"
                value="Ja"
                class="md-primary"
                :disabled="
                  !isFieldActive(field.field_dependency, field.field_condition)
                "
              >
                Ja
              </md-radio>
              <md-radio
                v-model="submission[field.field_key].field_answer"
                value="Nein"
                :disabled="
                  !isFieldActive(field.field_dependency, field.field_condition)
                "
              >
                Nein
              </md-radio>
            </div>

            <!-- Single choice -->
            <div class="iq-yesno" v-if="field.field_type === 'single_choice'">
              <div v-for="(option, index) in field.field_items" :key="index">
                <md-radio
                  v-model="submission[field.field_key].field_answer"
                  :value="option"
                  :disabled="
                    !isFieldActive(
                      field.field_dependency,
                      field.field_condition
                    )
                  "
                >
                  {{ option }}
                </md-radio>
              </div>
            </div>

            <!-- Multiple choice -->
            <div class="iq-yesno" v-if="field.field_type === 'multiple_choice'">
              <div v-for="(option, index) in field.field_items" :key="index">
                <md-checkbox
                  v-model="submission[field.field_key].field_multi_answer"
                  :value="option"
                  :disabled="
                    !isFieldActive(
                      field.field_dependency,
                      field.field_condition
                    )
                  "
                >
                  {{ option }}
                </md-checkbox>
              </div>
            </div>

            <!-- Additional Info -->
            <div class="survey--field-description" v-if="field.field_helper">
              {{ field.field_helper }}
            </div>

            <md-tooltip
              v-if="
                !isFieldActive(field.field_dependency, field.field_condition)
              "
              md-direction="top"
            >
              Kondition trifft nicht zu, bitte weiterfahren
            </md-tooltip>
          </div>

          <!-- Edit button -->
          <md-button
            v-if="isEditable && field.id && isComponentActive(['admin'])"
            class="md-primary md-icon-button survey--field-copy"
            @click="copyFieldID(field.id)"
          >
            <md-icon>content_copy</md-icon>
            <md-tooltip md-direction="left">ID kopieren</md-tooltip>
          </md-button>

          <!-- Edit button -->
          <md-button
            v-if="isEditable && isComponentActive(['admin'])"
            class="md-primary md-icon-button survey--field-edit"
            @click="emitEditField(field.field_key, field.field_position)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Feld bearbeiten</md-tooltip>
          </md-button>

          <!-- Delete button -->
<!--          <md-button-->
<!--            v-if="isEditable && isComponentActive(['admin'])"-->
<!--            class="md-accent md-icon-button survey&#45;&#45;field-delete"-->
<!--            @click="emitRemoveField(field.field_key)"-->
<!--          >-->
<!--            <md-icon>delete</md-icon>-->
<!--            <md-tooltip md-direction="left">Feld entfernen</md-tooltip>-->
<!--          </md-button>-->

          <!-- Delete Answer -->
          <md-button
            v-if="!isEditable"
            class="md-primary md-icon-button survey--field-undo"
            @click="undoAnswer(field.field_key, field.field_type)"
          >
            <md-icon>undo</md-icon>
            <md-tooltip md-direction="left">Antwort löschen</md-tooltip>
        </md-button>
        </div>

        <!-- Send and preview button -->
        <md-button
          class="md-primary button-adjuster"
          @click="toggleDialog('previewSubmission')"
          v-if="isEditable"
          >Vorschau Antworten</md-button
        >
        <div v-else class="button-container">
        <md-button
          class="md-raised md-primary button-adjuster"
          @click="emitSubmissionSave()"
          :disabled="isSendButtonEnabled"
          >Speichern</md-button
        >

        <md-button
          class="md-primary button-adjuster"
          style="border: 1px solid rgb(68, 138, 255)"
          @click="emitSubmission()"
          :disabled="isSendButtonEnabled"
          >Absenden</md-button
        >
        </div>
      </div>
    </div>

    <!-- preview submission dialog -->
    <md-dialog :md-active="dialogs.previewSubmission">
      <md-dialog-title>Vorschau der Antworten</md-dialog-title>

      <md-dialog-content>
        <md-table>
          <md-table-row>
            <md-table-head>Frage</md-table-head>
            <md-table-head>Antwort</md-table-head>
          </md-table-row>

          <md-table-row v-for="item in submission" :key="item.field_key">
            <md-table-cell>{{ item.field_question }}</md-table-cell>
            <md-table-cell>{{ item.field_answer | emptyAnswer }}</md-table-cell>
          </md-table-row>
        </md-table>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-accent" @click="toggleDialog('previewSubmission')"
          >Schließen</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import accessControlMixin from "@/mixins/accessControlMixin";

export default {
  name: "surveyRenderer",
  mixins: [dialogMixin, accessControlMixin],
  props: {
    isEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fields: {
      type: Array,
      required: true,
    },
    submission: {
      type: Object,
      required: true,
    },
    isSendButtonEnabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      dialogs: {
        previewSubmission: false,
      },
      sortedFields: [],
    };
  },
  created() {
    this.sortedFields = this.fields
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .sort((a, b) => a.field_position - b.field_position)
  },
  updated() {
    this.sortedFields = this.fields;
  },
  filters: {
    rangeSliderFilter(value) {
      return value === null ? 0 : value;
    },
    emptyAnswer(val) {
      if (!val) return "k.A.";
      return val;
    },
  },
  methods: {
    shouldShowInactiveFields(isActive) {
      if (!isActive) return true;
      return this.isEditable ? true : isActive;
    },
    emitRemoveField(fieldKey) {
      this.$emit("remove-field", fieldKey);
    },
    emitEditField(fieldKey, fieldPosition) {
      this.$emit("edit-field", { key: fieldKey, position: fieldPosition });
    },
    emitSubmissionSave() {
      this.$emit("saveSurvey", this.submission);
    },
    emitSubmission() {
      this.$emit("submitSurvey", this.submission);
    },
    undoAnswer(fieldKey, fieldType) {
      if(fieldType === "multiple_choice"){
        this.submission[fieldKey].field_multi_answer = [];
      }
      this.submission[fieldKey].field_answer = '';
    },
    async copyFieldID(id) {
      try {
        await navigator.clipboard.writeText(id);
      } catch (e) {
        console.error(e);
      }
    },
    isFieldActive(dependency, condition) {
      if (!this.submission[dependency]) {
        return false;
      }

      return this.submission[dependency].field_answer === condition || this.submission[dependency].field_multi_answer.includes(
          condition
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/survey";
@import "../../assets/css/mixins";

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.md-dialog-content {
  width: 800px;
}

.md-table-row > .md-table-cell:first-child {
  border-right: 1px solid var(--iq-gray-300);
}

.lower-opacity {
  filter: opacity(0.25);
}

.disabled-cursor {
  cursor: not-allowed !important;
}
</style>
